@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/thumbs';

.custom-swiper {
  --swiper-navigation-color: #fff;
  --swiper-pagination-color: #fff;
}

:root {
  --background: #ffffff;
  --foreground: #171717;
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: Arial, Helvetica, sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinking-cursor {
  animation: blink 1s step-start infinite;
}
::-webkit-scrollbar {
  width: 2px; 
  height: 2px; 
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 10px; 
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}
ul {
  list-style-position: inside;
  padding-left: 0;
  margin-left: 0; 
}
ul li {
  margin-left: 1em;
}
.text-center {
  text-align: center;
}
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}
.container {
  padding-left: 1rem;
  padding-right: 1rem;
}
header, main {
  margin: 0;
  padding: 0;
}
.main-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.description-container {
  max-height: auto; 
  overflow-y: auto; 
}
@media (max-width: 768px) {
  .container {
    padding-left: 0.5rem; 
    padding-right: 0.5rem;
  }
  main {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}
.lightbox-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  text-align: center;
}
.lightbox-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; 
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  padding: 5px 10px;
  cursor: pointer;
}
.close-btn:hover {
  background: rgba(0, 0, 0, 0.8);
}
.thumbnail-btn img {
  cursor: pointer;
  border-radius: 8px;
  transition: transform 0.3s ease;
}
.thumbnail-btn img:hover {
  transform: scale(1.1);
}
.image-container {
  width: 100%;
  max-width: 300px;
  aspect-ratio: 4 / 3;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product-card {
  display: flex;
  flex-direction: row;
  overflow: hidden; 
  width: 100%; 
}
.product-name {
  overflow: hidden;
  text-overflow: ellipsis; 
  white-space: nowrap; 
  font-size: clamp(1rem, 2vw, 1.25rem); 
}
.product-brand {
  font-size: clamp(0.875rem, 1.5vw, 1rem); 
}
.product-card .text {
  font-size: 1rem; 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal; 
  word-wrap: break-word; 
  line-clamp: 2;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/roboto-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/roboto-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}
.footer {
  min-height: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
